import React from "react";
import reactIcon from './images/icons/react-original.svg';
import nodeJsIcon from './images/icons/nodejs-plain.svg';
import gitIcon from './images/icons/git-plain.svg';
import html5Icon from './images/icons/html5-plain.svg';
import jsIcon from './images/icons/javascript-plain.svg';

class ImgIcon {
    constructor(img,title = "",altText) {
        this.img = img;
        this.title = title;
        this.altText = altText;
    }
}

const icons = [
    new ImgIcon(jsIcon,"Javascript","Javascript icon"),
    new ImgIcon(html5Icon,"HTML","HTML5 icon"),
    new ImgIcon(nodeJsIcon,"NodeJS","NodeJS icon"),
    new ImgIcon(reactIcon,"React","React icon"),
    new ImgIcon(gitIcon,"GIT","GIT icon"),
]

function ToolsImgs() {
    return (
        <div className="rsp-row-col-child-2">
            <div className="tools-container">
            {icons.map((icon) => {
                return (
                <div className="tool-container">
                    <img className="tool-icon" alt={icon.altText} src={icon.img} />
                    <p className="tool-text">{icon.title}</p>
                </div>
                )
            })}
            <div className="tool-container">
                <span className="tool-icon" style={{display:'block',backgroundColor:'#FFCB2B',height:'24px',borderRadius:'50%'}}/>
                <p className="tool-text">Firebase</p>
            </div>
            <div className="tool-container">
                <span className="tool-icon" style={{display:'block',backgroundColor:'#FF9A01',height:'24px',borderRadius:'50%'}}/>
                <p className="tool-text">AWS</p>
            </div>
            </div>
        </div>
    )
}

export default ToolsImgs;