import { useState } from 'react';

function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  function toggleOpen() {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <button tabIndex={1} aria-label={`${isOpen ? 'Close' : 'Menu'}`} type="button" onClick={toggleOpen} className={'hamburger ' + (isOpen ? 'is-open' : '')}>
        <span />
        <span />
        <span />
      </button>
      <div className={'menu-mobile ' + (isOpen ? 'is-open' : '')}>
        <a href="#about" onClick={toggleOpen}>About</a>
        <a href="#projects" onClick={toggleOpen}>Projects</a>
        <a href="#contact" onClick={toggleOpen}>Contact</a>
      </div>
    </>
  )
}

function MainNavbar() {
  return (
    <nav className='max-width section-container no-padding'>
      <div className='section-content navbar-container'>
        <a href='#'>
          <h2>GB</h2>
        </a>
        <div className='menu'>
          <a href="#about">About</a>
          <a href="#projects">Projects</a>
          <a href="#contact">Contact</a>
        </div>
        <MobileMenu />
      </div>
    </nav>
  )
}

export default MainNavbar;