import React from "react";

function AnimatedBackground() {
    return (
        <span className="hero-anim-container">
            <span />
            <span />
            <span />
        </span>
    )
}

function Hero() {
    return (
        <div className="max-width section-container hero-container">
            <div className="section-content">
                <h1>
                    Hello, I'm Giane
                </h1>
                <h2>
                    A software and web developer.
                </h2>

            </div>
            <AnimatedBackground />
        </div>
    )
}

export default Hero;