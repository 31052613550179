import React from "react";
import ContactInfo from "./contactInfo.json"

import linkedinIcon from './images/icons/linkedIn-icon.svg';
import mailIcon from './images/icons/mail-outline.svg';

function Contact() {
  return (
    <footer id="contact" className="max-width section-container contact-container">
      <h2>
        Get In Touch
      </h2>
      <div className="contact-channel-container">
        <div className="contact-channel-item">
          <a type="button" href={`mailto:${ContactInfo.email ? ContactInfo.email : ''}`} target="_blank" rel="noreferrer">
            <img src={mailIcon} className="contact-icon" alt="mail" />
          </a>
        </div>
        <div className="contact-channel-item">
          <a type="button" href={ContactInfo.linkedin} target="_blank" rel="noreferrer">
            <img src={linkedinIcon} className="contact-icon" alt="mail" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Contact;