import React from "react";
import ToolsImgs from "./ToolsImgs";


function About() {
  return (
    <section id="about" className="max-width section-container about-container">
      <div className="section-content">
      <h2>
        About me
      </h2>
      <div className="rsp-row-col-1">
        <div className="rsp-row-col-child-1">
        <p>
          A learning driven individual fascinated by all things digital and technology. Interested in software, web development and cloud technologies.
          Recent project experiences have utilised React and Firebase to develop applications.
        </p>
        </div>
        <ToolsImgs />
      </div>
      </div>
    </section>
  )
}

export default About;