import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, A11y } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import phone from './images/icons/phone.svg';
import img1 from './images/e4s/login.PNG';
import img2 from './images/e4s/home.PNG';
import img3 from './images/e4s/main.PNG';
import img4 from './images/e4s/video.PNG';
import img5 from './images/e4s/journal.PNG';
import ProjectLinks from './project-links.json'


const imgsPrj01 = [
	{
		img: img1,
		alt: 'Login page of the Elements 4 Success mobile app'
	},
	{
		img: img2,
		alt: 'Home page of the Elements 4 Success mobile app'
	},
	{
		img: img3,
		alt: 'Main page of "21 Day Lead Yourself Challenge" program in the Elements 4 Success mobile app'
	},
	{
		img: img4,
		alt: 'Sample video page for the "21 Day Lead Yourself Challenge" program in the Elements 4 Success mobile app'
	},
	{
		img: img5,
		alt: 'Main page of journal feature for the "21 Day Lead Yourself Challenge" program in the Elements 4 Success mobile app'
	},
]

function ImageCarousel() {
	return (
		<div className="project-img-container">
			<Swiper
				color="#FF0000"
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				}}
				spaceBetween={50}
				modules={[Navigation, Pagination, Autoplay, A11y]}
				navigation
				pagination={{ clickable: true }}
				slidesPerView={2}>
				{imgsPrj01.map((imgItem, index) => {
					return (
						<SwiperSlide>
							<img alt={imgItem.alt} style={{ width: '100%' }} src={imgItem.img} />
						</SwiperSlide>
					)
				})}
			</Swiper>
		</div>
	)
}

function Projects() {
	return (
		<section id="projects" className="max-width section-container root-project-container" >
			<div className="section-content">


				<h2>
					Projects
				</h2>
				<div className="projectContainer">
					<div className="project-info-cont">
						<div className="project-title-container">
							<div className="project-type-icon">
								<div className="flex-centered">
									<img alt="Mobile phone icon" src={phone} className="img-100-w" />
								</div>
							</div>
							<span className="v-sep" />
							<div className="project-titles">
								<h3>
									Elements 4 Success
								</h3>
								<h3 className="secondary">
									Mobile Application
								</h3>
							</div>
						</div>
						<p>
							A QUT Capstone project with <a href={ProjectLinks.e4s_home} target="_blank" rel="noreferrer">Elements 4 Success</a> that delivered the MVP for the Elements 4 Success app.
							The application provides access to exclusive life coaching courses and resources with a focus
							on the company's "21 Day Lead Yourself Challenge."
							The technology stack included React Native and Firebase.
						</p>
						<a href={ProjectLinks.e4s_app} target="_blank" rel="noreferrer" className="button-1">
							SEE APP
						</a>
					</div>
					<ImageCarousel />
				</div>
			</div>
		</section>
	)
}

export default Projects;