import MainNavbar from './NavBar';
import Hero from './Hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import About from './About';
import Contact from './Contact';
import Projects from './Projects';

function App() {
  return (
    <>
      <MainNavbar />
      <main>
        <div className="app">
          <Hero />
          <About />
          <Projects />
        </div>
      </main>
      <Contact />
    </>
  );
}

export default App;
